/*
  * {
   outline: 1px solid red;
   flex: 1 100%;
  }
*/

th, td {
  padding: 10px;
}

#lyrics {
  -webkit-overflow-scrolling: touch;
}
